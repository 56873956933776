<template>
  <div>test component 2</div>
</template>

<script>
export default {
  setup() {},

  data: function () {
    return {
      test: "test data not set",
      test2: "test2 data not set",
    };
  },
};
</script>

<style scoped lang="scss"></style>
